import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { FormattedMessage } from 'react-intl'

class InOtherLanguage extends React.Component {
  render () {
    const InLanguageLink = (props) => {
      return (
        <Link to={props.lang.link} key={props.lang.langKey} className='underline'>
          <FormattedMessage id={props.lang.langKey} /><br />
        </Link>
      )
    }

    return (
      <p className='text-center px-2 pt-2 pb-3 bg-gray'>
        {this.props.langs.map(lang => !lang.selected && <InLanguageLink lang={lang} key={lang.langKey} />)}
      </p>
    )
  }
}

InOtherLanguage.propTypes = {
  langs: PropTypes.array
}

export default InOtherLanguage
