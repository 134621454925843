module.exports = {
  'es-MX': 'En Español', // How you say "In `Locale`" in their locale
  'page-not-found-title': 'Page Not Found',
  'page-not-found-body': "You just hit a URL that doesn't exist.",
  'page-not-found-link': 'Try the home page',
  'more-help-title': 'More help and information',
  'check-list-title': 'Checklists',
  'where-to-find-title': 'Where to Find Help',
  'more-resources-link': 'More Resources',
  'things-can-do-title': 'Things You Can Do',
  'search-result-for-title': 'Search results for',
  'logo-title': 'Parent Guide',
  'california-title': 'California',
  'btn.save': 'SAVE',
  'btn.print': 'PRINT',
  'btn.resources': 'RESOURCES',
  'btn.about': 'ABOUT',
  'btn.resource': 'RESOURCE',
  copyright: '© 2019 The Regents of the University of California. All rights reserved. No part of this publication can be reproduced, published, adapted, distributed or transmitted in any form, by any means (including electronic, microcopying, photocopying, or otherwise) without prior written permission of the copyright owner.',
  'link.index': 'Index',
  'link.about': 'About',
  'link.resources': 'Resources',
  'link.menu': 'Menu',
  'link.home': 'Home',
  'table.cell.name': 'Name',
  'table.cell.description': 'Description',
  'table.cell.phone': 'Phone',
  'table.cell.website': 'Website',
  'table.cell.chapter': 'Chapter',
  'table.cell.sectionName': 'Section Name',
  'table.cell.introduction': 'Introduction',
  'search.placeholder': 'What are you looking for?',
  'search.noresult': 'No results have been found'
}
