import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <SEO title='404: Not found' />
    <div className='main-content container px-4 mx-auto top-0'>
      <h1 className='text-3xl text-turqouise font-bold my-8'><FormattedMessage id='page-not-found-title' /></h1>
      <p className='my-8'><FormattedMessage id='page-not-found-body' /> <Link to='/' className='text-turqouise underline'><FormattedMessage id='page-not-found-link' /></Link>.</p>
    </div>
  </Layout>
)

export default NotFoundPage
