/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Location } from '@reach/router'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n'

import { IntlProvider, addLocaleData } from 'react-intl'
import 'intl'

import en from 'react-intl/locale-data/en'
import 'intl/locale-data/jsonp/en'

import es from 'react-intl/locale-data/es'
import 'intl/locale-data/jsonp/es'

import Header from './header'
import Footer from './footer'

import '../styles/algolia.scss'
import '../styles/layout.scss'

addLocaleData([...en, ...es])

const Layout = ({
  children,
  location,
  heroUrl,
  heroAltText,
  locale,
  setSections
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            languages {
              defaultLangKey
              langs
            }
          }
        }
        allContentfulTableOfContents {
          group(field: node_locale) {
            fieldValue
            edges {
              node {
                id
                title
                sections {
                  id
                  title
                  icon {
                    id
                    file {
                      url
                    }
                  }
                  chapters {
                    title
                    slug
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const url = location ? location.pathname : '/'
      const { langs, defaultLangKey } = data.site.siteMetadata.languages

      let urlParts = url.split('/')
      urlParts = urlParts.filter(Boolean) // remove empty strings

      const pageType = urlParts.length > 1 ? urlParts[1] : 'home'
      const isAboutPage = pageType === 'about'
      const isHomePage = pageType === 'home'
      // const isResourcePage = pageType === 'resources' // unused
      // const isChapterPage = pageType === 'chapter' // unused

      const heroClass = isHomePage || isAboutPage ? 'h-hero' : 'h-sm-hero'

      const langKey = getCurrentLangKey(langs, defaultLangKey, url)
      const homeLink = `/${langKey}`
      const langsMenu = getLangs(
        langs,
        langKey,
        getUrlForLang(homeLink, url)
      ).map(item => ({ ...item, link: item.link }))

      // get the appropriate message file based on langKey
      // at the moment this assumes that langKey will provide us
      // with the appropriate language code
      const currentLocalesMessages = require(`../data/messages/${langKey}`)

      // Return sections
      if (setSections) {
        const localeSections = data.allContentfulTableOfContents.group.find(
          group => group.fieldValue === langKey
        )
        setSections(
          localeSections ? localeSections.edges[0].node.sections : []
        )
      }

      return (
        <IntlProvider
          key={langKey}
          locale={langKey}
          messages={currentLocalesMessages}
          defaultLocale={defaultLangKey}
        >
          <Location>
            {/* this makes a lot of location data available: https://reach.tech/router/api/Location */}
            {({ location, history }) => (
              <div className='no-js flex flex-col'>
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  location={location}
                  languages={langs}
                  groupedSections={data.allContentfulTableOfContents.group}
                />
                <main
                  className='w-full mx-auto'
                  role='main'
                  id='maincontent'
                  name='maincontent'
                >
                  {heroUrl && (
                    <div>
                      <img
                        src={heroUrl}
                        alt={heroAltText}
                        longdesc='#heroLongDesc'
                        className={`top-0 left-0 w-full -mt-32 h-64 ie-object-cover lg:${heroClass}`}
                      />
                      <div
                        id='heroLongDesc'
                        name='heroLongDesc'
                        className='hidden'
                      >
                        {heroAltText}
                      </div>
                    </div>
                  )}
                  {children}
                </main>

                <Footer langs={langsMenu} />
              </div>
            )}
          </Location>
        </IntlProvider>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
