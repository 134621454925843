import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import MenuList from './menu-list'
import logo from '../images/first-5-california-logo.png'
import searchIcon from '../images/icon-search.svg'

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchKey: '',
      isOpenMenu: false,
      onMenuItem: false,
      onMenuComponent: false
    }

    this.onSearch = this.onSearch.bind(this)
    this.onClickMenu = this.onClickMenu.bind(this)
  }

  onSearch (e) {
    const locale = this.context.intl.locale || 'en-US'

    if (e.key === 'Enter') {
      navigate(`/${locale}/search?searchkey=${this.state.searchKey}`)
    }
  }

  onClickMenu () {
    this.setState({ isOpenMenu: !this.state.isOpenMenu })
  }

  render () {
    const { pathname } = this.props.location
    // /en-US/ and /es-MX/ are 7 long
    const isHome = this.props.location.pathname.length < 8
    const locale = this.context.intl.locale || 'en-US'

    return (
      <header className='relative z-50 min-w-full uppercase' role='banner'>
        <div id='skip'>
          <a
            href='#maincontent'
            className='sr-only sr-only-focusable bg-yellow font-bold'
          >
            Skip to main content
          </a>
        </div>
        {/* Mobile Nav */}
        {this.state.isOpenMenu &&
          <div className='flex lg:hidden w-full absolute top-10 left-0 flex-col mt-20 z-50'>
            <Link to={`/${locale}/about`} className='w-full p-4 border-white border-b-2 text-white hover:text-white bg-turqouise no-underline hover:bg-coral'><FormattedMessage id='link.about' /></Link>
            <div
              className='w-full p-4 border-white border-b-2 text-white bg-turqouise hover:bg-coral text-left uppercase'
              onClick={() => this.setState({ onMenuItem: !this.state.onMenuItem })}
            >
              <FormattedMessage id='link.menu' className='mb-4' />
            </div>
            {(this.state.onMenuItem || this.state.onMenuComponent) &&
              <div
                className='h-full bg-gray-400'
                onMouseEnter={() => this.setState({ onMenuComponent: true })}
                onMouseLeave={() => this.setState({ onMenuComponent: false })}
              >
                <MenuList groupedSections={this.props.groupedSections} locale={locale} isMobile />
              </div>}
            <Link to={`/${locale}/resources`} className='w-full p-4 border-white border-b-2 text-white bg-turqouise no-underline hover:text-white hover:bg-coral'><FormattedMessage id='link.resources' /></Link>
          </div>}

        <nav
          className='container flex justify-between items-center h-24 mx-auto px-6 lg:px-12 bg-turqouise border-yellow border-b-2 font-bold text-white'
          role='navigation'
        >
          <div className='flex items-center h-full mr-6'>
            <a
              href='http://www.first5california.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='bg-white'
            >
              <img
                src={logo}
                alt='First 5 California Logo'
                width='144'
                height='44'
                className='h-10 w-auto border-white border-2 ie-object-contain'
              />
            </a>
            {!isHome && (
              // @TODO clean up all Links, extend <Link>
              <Link to={'/' + locale} className='block text-white hover:text-white no-underline border-solid ml-2 lg:ml-6 pl-2 lg:pl-6 border-white border-l tracking-tight text-base lg:text-xl'>
                <FormattedMessage id='california-title' /><br /><FormattedMessage id='logo-title' />
              </Link>
            )}
          </div>
          {/* Hamburger Button */}
          <div className='block lg:hidden'>
            <button onClick={this.onClickMenu} className='flex items-center px-0 py-0 text-turqouise border-teal-400 hover:text-teal hover:border-white uppercase'>
              <svg
                className='fill-current h-8 w-8 text-white'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <FormattedMessage className='uppercase' id='link.menu' />
                <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
              </svg>
            </button>
          </div>

          {/* Desktop Nav */}
          <div className='hidden lg:flex justify-end items-center w-full lg:w-auto h-full'>
            <div className='flex h-full relative'>
              {isHome &&
                <Link
                  to={'/' + locale + '/about'}
                  className='inline-flex h-full items-center px-4 mr-4 text-white hover:text-white hover:bg-coral text-xl no-underline'
                >
                  <FormattedMessage id='link.about' />
                </Link>}
              <div
                className='inline-flex h-full cursor-pointer items-center px-4 mr-4 no-underline text-xl text-white hover:bg-coral hover:text-white'
                onMouseEnter={() => this.setState({ onMenuItem: true })}
                onMouseLeave={() => this.setState({ onMenuItem: false })}
              >
                <FormattedMessage id='link.menu' />
                {(this.state.onMenuItem || this.state.onMenuComponent) &&
                  <div
                    className='absolute h-full'
                    style={{ width: '350px', top: 95, left: 0 }}
                    onMouseEnter={() => this.setState({ onMenuComponent: true })}
                    onMouseLeave={() => this.setState({ onMenuComponent: false })}
                  >
                    <MenuList groupedSections={this.props.groupedSections} locale={locale} />
                  </div>}
              </div>
              <Link
                to={'/' + locale + '/resources'}
                className={`inline-flex h-full items-center px-4 mr-4 no-underline text-xl text-white hover:bg-coral hover:text-white ${pathname === '/resources' ? 'bg-coral' : ''}`}
              >
                <FormattedMessage id='link.resources' />
              </Link>

              {((this.props.location.pathname !== `/${locale}/`) && (this.props.location.pathname !== `/${locale}`)) && (
                <form className='flex items-center h-full ml-8' role='search' aria-label='Sitewide' onSubmit={e => e.preventDefault()}>
                  <label htmlFor='searchInputHeader' className='sr-only text-white'>Search Site</label>
                  <input
                    id='searchInputHeader'
                    className='w-56 h-10 p-2 pl-4 bg-white text-black'
                    type='text'
                    aria-label='Search Site'
                    aria-labelledby='searchButtonHeader'
                    onKeyDown={this.onSearch}
                    onChange={e => this.setState({ searchKey: e.target.value })}
                  />
                  <button onClick={e => navigate(`/${locale}/search?searchkey=${this.state.searchKey}`)} id='searchButtonHeader' className='bg-coral w-10 h-10'>
                    <img src={searchIcon} alt='' className='w-10 h-10 p-3 ie-object-contain' />
                    <span className='sr-only text-white'>Search Site</span>
                  </button>
                </form>
              )}
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

// Make `this.context.intl` available.
Header.contextTypes = {
  intl: PropTypes.object
}

export default Header
