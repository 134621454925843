import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'
import { Link } from 'gatsby'
import 'react-accessible-accordion/dist/fancy-example.css'

class MenuList extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      expands: []
    }
  }

  render () {
    const { groupedSections } = this.props
    const { expands } = this.state

    const locale = this.context.intl.locale || 'en-US'

    // get the right locale's sections
    let sections = []
    groupedSections.forEach((section, key) => {
      if (section.fieldValue === locale) {
        sections = section.edges[0].node.sections
      }
    })

    return (
      <div className='w-full text-left leading-tight'>
        <Accordion className='border-0 bg-gray' allowZeroExpanded onChange={e => this.setState({ expands: e })} preExpanded={expands}>
          {
            sections.map((section, index) =>
              <AccordionItem uuid={'menu_item' + index} key={`accordion_${index}`}>
                <AccordionItemHeading>
                  <AccordionItemButton className='hover:bg-coral hover:text-white border-none'>
                    <div className={`flex relative justify-between items-center px-2 text-black hover:bg-coral bg-gray hover:text-white ${expands.includes(index) ? 'bg-coral text-white' : ''}`}>
                      <h2 className={`w-full sm:text-lg w-80 pl-2 py-4 pr-8 border-white border-b-2 ${expands.includes(index) ? '' : ''}`}>{section.title}</h2>
                      <span className='absolute text-xs' style={{ right: '1rem' }}>{!expands.includes(index) ? '〉' : '﹀'}</span>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='px-0' aria-label={`accordion_item_panel_${index}`}>
                  {
                    section.chapters.map((chapter, idx) =>
                      <Link key={idx} to={`/${this.props.locale}/chapter/${chapter.slug}`} className='ml-2 mr-2 pl-6 p-2 block bg-gray text-base border-white border-b-2 text-black no-underline hover:text-white hover:bg-coral flex capitalize'>
                        <span className='text-sm pr-4'>&bull;</span>{chapter.title}
                      </Link>
                    )
                  }
                </AccordionItemPanel>
              </AccordionItem>
            )
          }
        </Accordion>
      </div>
    )
  }
}

// Make `this.context.intl` available.
MenuList.contextTypes = {
  intl: PropTypes.object
}

export default MenuList
