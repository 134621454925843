import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import InOtherLanguage from './in-other-language'

import objectFitImages from 'object-fit-images'

import logo from '../images/first-5-california-logo.png'
import searchIcon from '../images/icon-search.svg'
import facebookIcon from '../images/icon-facebook.svg'
import instagramIcon from '../images/icon-instagram.svg'
import pinterestIcon from '../images/icon-pinterest.svg'
import twitterIcon from '../images/icon-twitter.svg'
import youtubeIcon from '../images/icon-youtube.svg'

class Footer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchKey: ''
    }

    this.onSearch = this.onSearch.bind(this)
  }

  onSearch (e) {
    const locale = this.context.intl.locale || 'en-US'

    if (e.key === 'Enter') {
      navigate(`/${locale}/search?searchkey=${this.state.searchKey}`)
    }
  }

  render () {
    const locale = this.context.intl.locale || 'en-US'
    // @TODO move to global, we cannot put this as a formatted message for some reason.
    const searchPlaceholder = (locale === 'es-MX') ? '¿Que estás buscando?' : 'What are you looking for?'

    // IE object fit polyfill
    objectFitImages()

    return (
      <footer role='contentinfo'>
        <div className='bg-turqouise p-5 lg:p-4 border-yellow border-t-2 pt-5 lg:pt-10 flex-col'>
          <div className='flex container flex-col mx-auto justify-between lg:flex-row px-0 lg:px-5'>
            <div className='flex flex-no-shrink text-white mr-6 w-full leading-tight'>
              <div className='mr-8'>
                <a
                  href='http://www.first5california.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='bg-white'
                >
                  <img
                    src={logo}
                    alt='First 5 California Logo'
                    width='144'
                    height='44'
                    className='h-10 w-auto bg-white border-white border-2 ie-object-contain'
                  />
                </a>
              </div>
              <div className='flex-row'>
                <div>
                  <a className='text-white hover:text-white underline' target='blank' href='https://www.google.com/maps/place/2389+Gateway+Oaks+Dr+%23260,+Sacramento,+CA+95833/@38.6111488,-121.5178892,17z/data=!3m1!4b1!4m5!3m4!1s0x809ad68c98606d0f:0x6ed2d6f049b0efd4!8m2!3d38.6111488!4d-121.5157005'>
                    <p>2389 Gateway Oaks Dr</p>
                    <p>Ste 260</p>
                    <p>Sacramento, CA 95833</p>
                  </a>
                </div>
                <div className='my-3'>
                  <a className='text-white hover:text-white underline' target='_blank' rel='noreferrer noopener' href='tel:916-263-1050'>
                    916-263-1050
                  </a>
                </div>
                <div>
                  <a className='font-bold text-white hover:text-white underline' target='_blank' rel='noreferrer noopener' href='mailto:info@ccfc.ca.gov?subject=First%205%20California%20Parent%20Guide'>info@ccfc.ca.gov</a>
                </div>
              </div>
            </div>
            <div className='w-full block justify-end flex-col lg:flex lg:w-full px-0 lg:px-10 text-xs leading-tight'>
              <label htmlFor='searchInputFooter' className='sr-only text-white'>Search The Site</label>
              <form className='hidden lg:flex mr-4 lg:flex-no-wrap lg:mt-0 mb-6 w-full' role='search' aria-label='Global' onSubmit={e => e.preventDefault()}>
                <input
                  id='searchInputFooter'
                  className='w-full h-10 p-2 pl-4 bg-white'
                  type='text'
                  placeholder={searchPlaceholder}
                  arial-label='Global Search'
                  aria-labelledby='searchButtonFooter'
                  onChange={e => this.setState({ searchKey: e.target.value })}
                  onKeyDown={this.onSearch}
                />
                <button id='searchButtonFooter' className='bg-coral w-10 h-10' onClick={e => navigate(`/${locale}/search?searchkey=${this.state.searchKey}`)}>
                  <img src={searchIcon} alt='' className='w-10 h-10 p-3 ie-object-contain' />
                  <span className='sr-only text-white'>Search The Site</span>
                </button>
              </form>
              <div className='hidden lg:flex text-lg lg:flex-grow text-lg w-full font-bold'>
                <Link
                  to={'/' + locale + '/about'}
                  className='block mt-4 lg:inline-block lg:mt-0 text-white no-underline hover:text-white mr-4'
                >
                  <FormattedMessage id='btn.about' />
                </Link>
                {/* // @todo figure out active state */}
                <Link
                  to={'/' + locale + '/resources'}
                  className='block mt-4 lg:inline-block lg:mt-0 text-white no-underline hover:text-white mr-4'
                >
                  <FormattedMessage id='btn.resource' />
                </Link>
              </div>
              <div className='flex mt-8'>
                <a href='https://www.facebook.com/first5california' target='_blank' rel='noopener noreferrer'><img src={facebookIcon} alt='Facebook icon' className='w-8 h-8 mr-3 ie-object-contain' /></a>
                <a href='https://twitter.com/First5CA' target='_blank' rel='noopener noreferrer'><img src={twitterIcon} alt='Twitter icon' className='w-8 h-8 mr-3 ie-object-contain' /></a>
                <a href='https://www.instagram.com/first5california/' target='_blank' rel='noopener noreferrer'><img src={instagramIcon} alt='Instagram icon' className='w-8 h-8 mr-3 ie-object-contain' /></a>
                <a href='https://www.pinterest.com/first5ca/' target='_blank' rel='noopener noreferrer'><img src={pinterestIcon} alt='Pinterest icon' className='w-8 h-8 mr-3 ie-object-contain' /></a>
                <a href='https://www.youtube.com/user/First5CA' target='_blank' rel='noopener noreferrer'><img src={youtubeIcon} alt='YouTube icon' className='w-8 h-8 mr-3 ie-object-contain' /></a>
              </div>
              <p className='mt-8 mb-4 text-white hover:text-white'><FormattedMessage id='copyright' /></p>
            </div>
          </div>
        </div>
        <InOtherLanguage langs={this.props.langs} />
      </footer>
    )
  }
}

Footer.contextTypes = {
  intl: PropTypes.object
}

export default Footer
