module.exports = {
  'en-US': 'In English', // How you say "In Locale" in their locale
  'page-not-found-title': 'Página no encontrada',
  'page-not-found-body': 'Usted acaba de llegar a un URL que no existe.',
  'page-not-found-link': 'Prueba la página de inicio',
  'more-help-title': 'Más ayuda e información',
  'check-list-title': 'Checklists',
  'where-to-find-title': 'Dónde puede encontrar ayuda',
  'more-resources-link': 'Más recursos',
  'things-can-do-title': 'Lo que usted puede hacer',
  'search-result-for-title': 'Resultados',
  'logo-title': 'de California',
  'california-title': 'Guía para padres',
  'btn.save': 'GUARDAR',
  'btn.print': 'IMPRIMIR',
  'btn.resources': 'Recursos',
  'btn.about': 'Acerca',
  'btn.resource': 'Recursos',
  copyright: ' 2019 por los Regentes de la Universidad de California. Todos los derechos reservados. Ninguna parte de esta publicación puede ser reproducida, publicada, adaptada, distribuida, o transmitida de ninguna forma ni por ningún medio (incluyendo medios electrónicos, microcopiado y fotocopiado) sin previa autorización escrita del propietario de los derechos.',
  'link.index': 'Indice',
  'link.about': 'Acerca',
  'link.home': 'Página de inicio',
  'link.resources': 'Recursos',
  'link.menu': 'Menu',
  'table.cell.name': 'Nombre',
  'table.cell.description': 'Descripción',
  'table.cell.phone': 'Teléfono',
  'table.cell.website': 'Website',
  'table.cell.chapter': 'Capítulo',
  'table.cell.sectionName': 'Section Name',
  'table.cell.introduction': 'Introducción',
  'search.placeholder': 'Qué estás buscando?',
  'search.noresult': 'No se encontraron resultados'
}
